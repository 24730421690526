import React from 'react'
import styled, { css } from 'styled-components'
import { FiDollarSign, FiClock } from 'react-icons/fi'
import { motion, AnimatePresence } from 'framer-motion'

import { PAGEURL } from '/constants'
import { HtmlInput } from '/components/admin/page/page_builder/form-inputs/Html'
import { Link as ChaliceLink } from '/components/theme/button/Button'
import { radial, FormWrapper } from '/components/theme/default/base'
import { StandardInput } from '/components/admin/page/page_builder/form-inputs/Standard'
import { MediaInputPB } from '/components/admin/page/page_builder/form-inputs/Media'
import { ColorPicker } from '../../form-inputs/ColorPicker'

// TODO: remove this dependency
import { H1, HeroSub } from '/components/theme/earthlink/theme'

export const HeroFullForm = ({
  hero_image,
  hero_title,
  hero_description,
  background_color,
  text_color,
  bubble_1_left,
  bubble_1_top,
  bubble_1_text,
  bubble_2_left,
  bubble_2_top,
  bubble_2_text,
  bubble_3_left,
  bubble_3_top,
  bubble_3_text,
  cta_text,
  cta_url,
  setCurrentComponent
}) => {
  return (
    <FormWrapper>
      <ColorPicker
        name="background_color"
        label="Background Color"
        value={background_color}
        setCurrentComponent={setCurrentComponent}
      />

      <ColorPicker
        name="text_color"
        label="Text Color"
        value={text_color}
        setCurrentComponent={setCurrentComponent}
      />

      <MediaInputPB
        image={hero_image}
        label="Hero Image"
        name="hero_image"
        setCurrentComponent={setCurrentComponent}
      />

      <HtmlInput
        value={hero_title}
        name="hero_title"
        label="Hero Title"
        setCurrentComponent={setCurrentComponent}
      />
      <p>
        <small>
          Use &quot;Bold&quot; to change the text to the primary color and
          &quot;Italicise&quot; to add the underline style.
        </small>
      </p>

      <HtmlInput
        value={hero_description}
        name="hero_description"
        label="Hero Description"
        setCurrentComponent={setCurrentComponent}
      />

      <StandardInput
        value={bubble_1_left}
        name="bubble_1_left"
        label="Bubble 1 Left"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />

      <StandardInput
        value={bubble_1_top}
        name="bubble_1_top"
        label="Bubble 1 Top"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />

      <StandardInput
        value={bubble_1_text}
        name="bubble_1_text"
        label="Bubble 1 Text"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />

      <StandardInput
        value={bubble_2_left}
        name="bubble_2_left"
        label="Bubble 2 Left"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />

      <StandardInput
        value={bubble_2_top}
        name="bubble_2_top"
        label="Bubble 2 Top"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />

      <StandardInput
        value={bubble_2_text}
        name="bubble_2_text"
        label="Bubble 2 Text"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />

      <StandardInput
        value={bubble_3_left}
        name="bubble_3_left"
        label="Bubble 3 Left"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />

      <StandardInput
        value={bubble_3_top}
        name="bubble_3_top"
        label="Bubble 3 Top"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />

      <StandardInput
        value={bubble_3_text}
        name="bubble_3_text"
        label="Bubble 3 Text"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />

      <StandardInput
        value={cta_text}
        name="cta_text"
        label="CTA Text"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />

      <StandardInput
        value={cta_url}
        name="cta_url"
        label="CTA Url"
        height="100px"
        setCurrentComponent={setCurrentComponent}
      />
    </FormWrapper>
  )
}

export const HeroFull = ({
  hero_image,
  hero_title = 'Benefits <strong>you</strong> can <em>use</em>',
  hero_description = 'Where the strength of we empowers a network of me. We connect you with link-minded professionals to share ideas or solicit advice.',
  text_color = null,
  background_color = null,
  bubble_1_left,
  bubble_1_top,
  bubble_1_text,
  bubble_2_left,
  bubble_2_top,
  bubble_2_text,
  bubble_3_left,
  bubble_3_top,
  bubble_3_text,

  cta_text = 'Explore the Marketplace',
  cta_url = PAGEURL.MARKETPLACE
}) => {
  const initial = {
    opacity: 0,
    x: -20,
    y: 20
  }
  const animate = {
    opacity: 1,
    x: 0,
    y: 0
  }
  return (
    <div>
      <AnimatePresence mode="wait">
        <Hero background_color={background_color}>
          <Background
            imageUrl={hero_image?.media_center?.media}
            initial={{ opacity: 0, scale: 1.05 }}
            animate={{ opacity: 1, scale: 1 }}
          />
          {bubble_1_text && (
            <Bubble
              top={bubble_1_top || '30%'}
              left={bubble_1_left}
              duration={500}
              initial={initial}
              animate={animate}
              transition={{ delay: 0.5, duration: 0.5 }}
            >
              <BubbleIcon>
                <FiDollarSign />
              </BubbleIcon>{' '}
              <BubleText>{bubble_1_text}</BubleText>
            </Bubble>
          )}
          {bubble_2_text && (
            <Bubble
              top={bubble_2_top || '50%'}
              left={bubble_2_left}
              initial={initial}
              animate={animate}
              transition={{ delay: 0.7, duration: 0.5 }}
            >
              <BubbleIcon bg="#0099a9">
                <FiClock />
              </BubbleIcon>{' '}
              <BubleText>{bubble_2_text}</BubleText>
            </Bubble>
          )}
          {bubble_3_text && (
            <Bubble
              top={bubble_3_top || '70%'}
              left={bubble_3_left}
              initial={initial}
              animate={animate}
              transition={{ delay: 0.8, duration: 0.5 }}
            >
              <BubbleIcon bg="#b4008e">
                <FiDollarSign />
              </BubbleIcon>{' '}
              <BubleText>{bubble_3_text}</BubleText>
            </Bubble>
          )}
          <HeroOverlay>
            <HeroContent text_color={text_color}>
              <H1
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2 }}
                dangerouslySetInnerHTML={{
                  __html: hero_title || ''
                }}
              />
              <HeroSub
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.25 }}
                dangerouslySetInnerHTML={{ __html: hero_description }}
              />
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.3 }}
              >
                <ChaliceLink href={cta_url}>{cta_text}</ChaliceLink>
              </motion.div>
            </HeroContent>
          </HeroOverlay>
        </Hero>
      </AnimatePresence>
    </div>
  )
}

const Hero = styled.div`
  min-height: 700px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  padding-top: var(--chalice-navbar-height);

  ${(props) =>
    props.background_color &&
    props.theme.minMedia.desktop`
    background-color: ${props.background_color};
  `}

  ${(props) => props.theme.media.tablet`
    background-color: #f2f2f2;
  `}
`

const Bubble = styled(motion.div)`
  position: absolute;
  z-index: 10;
  left: ${(props) => props.left || '70%'};
  top: ${(props) => props.top};
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  background-color: #fff;
  box-shadow: 2px 2px 16px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  overflow: hidden;
  gap: 10px;

  ${(props) => props.theme.media.tablet`
    display: none;
  `}

  ${(props) => props.theme.media.desktop`
    left: ${(props) => props.top};
    top: 80%;
  `}
`

const BubleText = styled.div`
  padding-right: 20px;
`

const BubbleIcon = styled.div`
  height: 60px;
  width: 60px;
  background-color: ${(props) => props.bg || '#3DAF2C'};
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;

  svg {
    height: 20px;
    width: 20px;
  }
`

const HeroOverlay = styled.div`
  width: 50%;
  z-index: 5;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  flex: 1 0 auto;

  ${(props) => props.theme.media.desktop`
    width: 100%;
  `}
`

const HeroContent = styled.div`
  width: 600px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px;

  ${(props) => props.theme.media.tablet`
    width: 100%;
  `}

  ${(props) =>
    props.text_color &&
    props.theme.minMedia.desktop`
    color: ${props.text_color};

    h1 {
      color: ${props.text_color};
    }
  `}
`

const BackgroundWrapper = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
  background-color: transparent;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    ${(props) => props.theme.media.tablet`
      display: none;
    `}
  }
`

const Background = ({ imageUrl, ...rest }) => {
  if (imageUrl) {
    return (
      <BackgroundWrapper {...rest}>
        <img src={imageUrl} alt="" />
      </BackgroundWrapper>
    )
  }
  return null
}

const Section = styled.div`
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  min-height: 400px;

  ${(props) =>
    props.coloredBg &&
    `
    background-color: ${props.theme.colors.secondary};
    background: ${radial(props.theme.colors.secondary)};
    padding: 50px 0;
    color: #fff;
  `}

  ${(props) =>
    props.grey &&
    `
    background-color: #f2f2f2;
  `}

  ${(props) => props.theme.media.tablet`
    padding: 100px 10px;
  `}
`
