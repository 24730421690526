import React from 'react'
import { TeamList } from './team'
import { WideImage } from './common'
import { inject, observer } from 'mobx-react'

import MarketplaceHero from '../../marketplace/MarketplaceHero'
import AccountSetup from '../../profile/AccountSetup'
import { Hero } from '../../admin/page/page_builder/components/hero/Hero'
import {
  HeroFull,
  HeroFullForm
} from '/components/admin/page/page_builder/components/hero/HeroFull'
import {
  LogoParade,
  LogoParadeForm
} from '/components/admin/page/page_builder/components/parade/LogoParade'
import { SmallHero } from '../../admin/page/page_builder/components/small-hero/SmallHero'
import { SmallHeroForm } from '../../admin/page/page_builder/components/small-hero/SmallHeroForm'
import { FeatureList } from '../../admin/page/page_builder/components/feature-list/FeatureList'
import { FeatureListForm } from '../../admin/page/page_builder/components/feature-list/FeatureListForm'
import { FeatureGrid } from '../../admin/page/page_builder/components/feature-grid/FeatureGrid'
import { FeatureGridForm } from '../../admin/page/page_builder/components/feature-grid/FeatureGridForm'
import { TextBlockForm } from '../../admin/page/page_builder/components/text-block/TextBlockForm'
import { TextBlock } from '../../admin/page/page_builder/components/text-block/TextBlock'
import { SideImage } from '../../admin/page/page_builder/components/side-image/SideImage'
import { SideImageForm } from '../../admin/page/page_builder/components/side-image/SideImageForm'
import { Split } from '../../admin/page/page_builder/components/split/Split'
import { SplitForm } from '../../admin/page/page_builder/components/split/SplitForm'
import { HubspotForm } from '../../admin/page/page_builder/components/hubpot-form/HubspotForm'
import { MarketplaceHeroForm } from '../../admin/page/page_builder/components/marketplace-hero/MarketplaceHeroForm'
import { HeroForm } from '../../admin/page/page_builder/components/hero/HeroForm'
import { Pillars } from '../../admin/page/page_builder/components/pillars/Pillars'
import { PillarsForm } from '../../admin/page/page_builder/components/pillars/PillarsForm'
import { HubspotFormForm } from '../../admin/page/page_builder/components/hubpot-form/HubspotFormForm'
import { MarketoFormForm } from '../../admin/page/page_builder/components/marketo-form/MarketoFormForm'
import { HtmlBlockForm } from '../../admin/page/page_builder/components/html-block/HtmlBlockForm'
import { HtmlBlock } from '../../admin/page/page_builder/components/html-block/HtmlBlock'
import { AccountSetupForm } from '../../admin/page/page_builder/components/account-setup/AccountSetupForm'
import { MarketoForm } from '../../admin/page/page_builder/components/marketo-form/MarketoForm'
import MarketplaceDisplay from '../../marketplace/MarketplaceDisplay'
import { HeroCapture } from '../../admin/page/page_builder/components/hero_capture/HeroCapture'
import { HeroCaptureForm } from '../../admin/page/page_builder/components/hero_capture/HeroCaptureForm'
import { BlogHero } from '../../admin/page/page_builder/components/blog/BlogHero'
import { BlogHeroForm } from '../../admin/page/page_builder/components/blog/BlogHeroForm'
import { BlogBody } from '../../admin/page/page_builder/components/blog/BlogBody'
import { BlogBodyForm } from '../../admin/page/page_builder/components/blog/BlogBodyForm'
import { PricingCompare } from '../../admin/page/page_builder/components/pricing/PricingCompare'
import { PricingCompareForm } from '../../admin/page/page_builder/components/pricing/PricingCompareForm'
import { PricingTiers } from '../../admin/page/page_builder/components/pricing/PricingTiers'
import { PricingTiersForm } from '../../admin/page/page_builder/components/pricing/PricingTiersForm'
import {
  Navbar,
  NavbarForm
} from '../../admin/page/page_builder/components/navbar/Navbar'

import ElMarketplaceDisplay, {
  ElMarketplaceDisplayForm
} from '/components/theme/earthlink/ElMarketplaceDisplay'
import Dashboard from '/components/theme/earthlink/ElDashboard'

import { THEME as EL_THEME } from '/components/theme/earthlink/theme'
import { THEME as FFR_THEME } from '/components/theme/ffr/theme'
import { THEME as HR_THEME } from '/components/theme/houstonfirst/theme'

export const ComponentSelector = inject('store')(
  observer(({ store, component, extraProps }) => {
    const { siteData } = store
    const Clazz = COMPONENT_LOOKUP(siteData)[component].component
    if (Clazz) {
      return <Clazz {...extraProps} />
    } else {
      console.error(`Unable to find ${component} Component. Skipping`)
      return null
    }
  })
)

export const ComponentRender = inject('store')(
  observer(
    ({ store, components, extraProps, currentComponent = {}, isPreview }) => {
      const { siteData } = store
      return components
        ?.map((c, i) => {
          const { component, props } = c
          const entry = COMPONENT_LOOKUP(siteData)[component]
          if (!entry) {
            return null
          }
          const Clazz = entry.component
          if (Clazz) {
            return (
              <Clazz
                key={c.id}
                {...props}
                {...extraProps}
                currentComponent={currentComponent}
                isPreview={isPreview}
              />
            )
          } else {
            console.error(`Unable to find ${component} Component. Skipping`)
            return null
          }
        })
        .filter((c) => c !== null)
    }
  )
)

export const ComponentFormRender = inject('store')(
  observer(({ store, component, setCurrentComponent, extraProps }) => {
    const { siteData } = store
    const entry = COMPONENT_LOOKUP(siteData)[component.component]
    if (!entry) {
      return null
    }
    const Clazz = entry.form
    if (Clazz) {
      return (
        <Clazz
          key={component.id}
          {...component.props}
          setCurrentComponent={setCurrentComponent}
          {...extraProps}
        />
      )
    } else {
      console.error(`Unable to find ${component}'s form.`)
      return null
    }
  })
)

export const pageRender = (
  page,
  extraProps = null,
  currentComponent = {},
  isPreview = false
) => {
  return (
    <ComponentRender
      components={page.data}
      extraProps={extraProps}
      currentComponent={currentComponent}
      isPreview={isPreview}
    />
  )
}

const THEMES = {
  earthlink: EL_THEME,
  'earthlink-biz': EL_THEME,
  ffr: FFR_THEME,
  'houston-first': HR_THEME
}

export const COMPONENT_LOOKUP = (siteData) => {
  const theme = THEMES[siteData.get('slug')]
  const baseComponents = {
    hero: {
      component: Hero,
      form: HeroForm,
      label: 'Hero',
      type: ['parent'],
      new: {
        component: 'hero',
        label: 'Hero',
        props: {
          title: 'TITLE',
          sub_title: 'Subtitle',
          has_cta: true,
          text_color: '#0f0f0f',
          text_shadow: false,
          logo: {
            opacity: 1,
            allignment: 'center',
            media_center: null,
            urls: ['/assets/images/placeholder.jpeg']
          },
          background_image: {
            opacity: 0.3,
            allignment: 'center',
            media_center: null,
            urls: ['/assets/images/placeholder.jpeg']
          }
        }
      }
    },
    heroFull: {
      component: HeroFull,
      form: HeroFullForm,
      label: 'Hero Full',
      type: ['parent'],
      new: {
        component: 'heroFull',
        label: 'Hero Full',
        props: {}
      }
    },
    logoParade: {
      component: LogoParade,
      form: LogoParadeForm,
      label: 'Logo Parade',
      type: ['parent'],
      new: {
        component: 'logoParade',
        label: 'Logo Parade',
        props: {}
      }
    },
    dashboard: {
      component: Dashboard,
      form: null,
      label: 'New Dashboard',
      type: ['parent'],
      new: {
        component: 'dashboard',
        label: 'New Dashboard',
        props: {}
      }
    },
    new_marketplace: {
      component: ElMarketplaceDisplay,
      form: ElMarketplaceDisplayForm,
      label: 'New Marketplace',
      type: ['parent'],
      new: {
        component: 'new_marketplace',
        label: 'New Marketplace',
        props: {
          companyName: 'COMPANY_NAME'
        }
      }
    },
    heroCapture: {
      component: HeroCapture,
      form: HeroCaptureForm,
      label: 'Hero w/Lead Capture',
      type: ['parent'],
      new: {
        component: 'heroCapture',
        label: 'Hero w/Lead Capture',
        props: {
          title: 'TITLE',
          sub_title: 'Subtitle',
          text_color: '#0f0f0f',
          text_shadow: false,
          logo: {
            opacity: 1,
            allignment: 'center',
            media_center: null,
            urls: ['/assets/images/placeholder.jpeg']
          },
          background_image: {
            opacity: 0.3,
            allignment: 'center',
            media_center: null,
            urls: ['/assets/images/placeholder.jpeg']
          }
        }
      }
    },
    small_hero: {
      component: SmallHero,
      form: SmallHeroForm,
      label: 'Small Hero',
      type: ['parent'],
      new: {
        component: 'small_hero',
        label: 'Small Hero',
        props: {
          title: 'TITLE',
          description: 'Description',
          has_cta: true,
          text_color: '#0f0f0f',
          text_shadow: false,
          background_image: {
            opacity: 0.3,
            allignment: 'center',
            media_center: null,
            urls: ['/assets/images/placeholder.jpeg']
          }
        }
      }
    },
    side_image: {
      component: SideImage,
      form: SideImageForm,
      label: 'Side Image',
      type: ['parent'],
      new: {
        component: 'side_image',
        label: 'Side Image',
        props: {
          title: 'TITLE',
          description: 'Description',
          has_cta: true,
          reverse: false,
          bg_color: 'white',
          text_color: '#0f0f0f',
          text_shadow: false,
          image: {
            opacity: 0.3,
            allignment: 'center',
            media_center: null,
            urls: ['/assets/images/placeholder.jpeg']
          }
        }
      }
    },
    feature_list: {
      component: FeatureList,
      form: FeatureListForm,
      label: 'Feature List',
      type: ['parent'],
      new: {
        component: 'feature_list',
        label: 'Feature List',
        props: {
          title:
            'Just take a look. If you are not impressed - you must be overpaying',
          titleColor: '',
          titleBackgroundColor: '',
          backgroundColor: '',
          feature1: 'Feature 1',
          feature2: 'Feature 2',
          feature3: 'Feature 3',
          feature4: 'Feature 4',
          feature5: 'Feature 5',
          feature6: 'Feature 6',
          feature7: 'Feature 7',
          feature8: 'Feature 8'
        }
      }
    },
    feature_grid: {
      component: FeatureGrid,
      form: FeatureGridForm,
      label: 'Feature Grid',
      type: ['parent'],
      new: {
        component: 'feature_grid',
        label: 'Feature Grid',
        props: {
          titleBackgroundColor: '',
          backgroundColor: '',
          feature1: 'Feature 1',
          feature2: 'Feature 2',
          feature3: 'Feature 3',
          feature4: 'Feature 4'
        }
      }
    },
    text_block: {
      component: TextBlock,
      form: TextBlockForm,
      label: 'Text Block',
      type: ['parent'],
      new: {
        component: 'text_block',
        label: 'Text Block',
        props: {
          textColor: '',
          backgroundColor: '',
          content: ''
        }
      }
    },
    split: {
      component: Split,
      form: SplitForm,
      label: 'Split',
      type: ['parent'],
      new: {
        component: 'split',
        label: 'Split',
        props: {
          title: 'TITLE',
          bg_color: 'white',
          left: [],
          right: []
        }
      }
    },
    pillars: {
      component: Pillars,
      form: PillarsForm,
      label: 'Pillars',
      type: ['parent'],
      new: {
        component: 'pillars',
        label: 'Pillars',
        props: {
          title: 'TITLE',
          bg_color: '#fafafa',
          subtitle: 'Subtitle',
          pillars: [
            {
              url: '',
              title: 'TITLE 1',
              description: 'Description 1',
              image: {
                opacity: 1,
                media_center: null,
                urls: ['/assets/images/placeholder.jpeg']
              }
            },
            {
              url: '',
              title: 'TITLE 2',
              description: 'Description 2',
              image: {
                opacity: 1,
                media_center: null,
                urls: ['/assets/images/placeholder.jpeg']
              }
            },
            {
              url: '',
              title: 'TITLE 3',
              description: 'Description 3',
              image: {
                opacity: 1,
                media_center: null,
                urls: ['/assets/images/placeholder.jpeg']
              }
            },
            {
              url: '',
              title: 'TITLE 4',
              description: 'Description 4',
              image: {
                opacity: 1,
                media_center: null,
                urls: ['/assets/images/placeholder.jpeg']
              }
            }
          ]
        }
      }
    },
    hubspot_form: {
      component: HubspotForm,
      form: HubspotFormForm,
      label: 'Hubspot Form',
      type: ['child'],
      new: {
        component: 'hubspot_form',
        label: 'Hubspot Form',
        props: {
          portal_id: '',
          form_id: ''
        }
      }
    },
    marketo_form: {
      component: MarketoForm,
      form: MarketoFormForm,
      label: 'Marketo Form',
      type: ['child'],
      new: {
        component: 'marketo_form',
        label: 'Marketo Form',
        props: {
          src: '',
          form_url: '',
          form_id: '',
          form_uuid: ''
        }
      }
    },
    team_list: TeamList,
    html_block: {
      component: HtmlBlock,
      form: HtmlBlockForm,
      label: 'Html Block',
      type: ['child', 'parent'],
      new: {
        component: 'html_block',
        label: 'Html Block',
        props: {
          content: 'Content'
        }
      }
    },
    wide_image: WideImage,

    marketplace_hero: {
      component: MarketplaceHero,
      form: MarketplaceHeroForm,
      label: 'Marketplace Hero',
      type: ['parent'],
      new: {
        component: 'marketplace_hero',
        label: 'Marketplace Hero',
        props: {
          title: 'TITLE',
          subtitle: 'Subtitle',
          description: 'Description'
        }
      }
    },
    marketplace: {
      component: MarketplaceDisplay,
      type: ['parent'],
      new: {
        component: 'marketplace',
        props: {}
      }
    },
    account_setup: {
      component: AccountSetup,
      form: AccountSetupForm,
      label: 'Account Setup',
      type: ['parent'],
      new: {
        component: 'marketplace_hero',
        label: 'Marketplace Hero',
        props: {
          title: 'TITLE',
          fields: []
        }
      }
    },
    blog_hero: {
      component: BlogHero,
      form: BlogHeroForm,
      label: 'Blog Hero',
      type: ['parent'],
      new: {
        component: 'blog_hero',
        label: 'Blog Hero',
        props: {
          title: 'Title',
          image: 'Image'
        }
      }
    },
    blog_body: {
      component: BlogBody,
      form: BlogBodyForm,
      label: 'Blog Posts',
      type: ['parent'],
      new: {
        component: 'blog_body',
        label: 'Blog Posts',
        props: {
          title: 'Title'
        }
      }
    },
    pricing_tiers: {
      component: PricingTiers,
      form: PricingTiersForm,
      label: 'Pricing Tiers',
      type: ['parent'],
      new: {
        component: 'pricing_tiers',
        label: 'Pricing tiers',
        props: {
          title: 'Title'
        }
      }
    },
    pricing_compare: {
      component: PricingCompare,
      form: PricingCompareForm,
      label: 'Pricing Compare',
      type: ['parent'],
      new: {
        component: 'pricing_compare',
        label: 'Pricing Compare',
        props: {
          title: 'Title'
        }
      }
    },
    navbar: {
      component: Navbar,
      form: NavbarForm,
      label: 'Navbar',
      type: ['parent'],
      new: {
        component: 'navbar',
        label: 'Navbar',
        props: {}
      }
    }
  }
  if (theme) {
    for (const c in theme) {
      baseComponents[c] = theme[c]
    }
  }
  return baseComponents
}
