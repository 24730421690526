import { FormWrapper } from '../../../../../theme/default/base'
import { BooleanInput } from '../../form-inputs/Boolean'
import { ColorPicker } from '../../form-inputs/ColorPicker'
import { HtmlInput } from '../../form-inputs/Html'
import { MediaInputPB } from '../../form-inputs/Media'
import { OpacityInput } from '../../form-inputs/Opacity'

export const TextBlockForm = ({
  content,
  textColor,
  backgroundColor,
  setCurrentComponent
}) => {
  return (
    <FormWrapper>
      <ColorPicker
        name={'textColor'}
        label="Text Color"
        value={textColor}
        setCurrentComponent={setCurrentComponent}
      />
      <ColorPicker
        name={'backgroundColor'}
        label="Background Color"
        value={backgroundColor}
        setCurrentComponent={setCurrentComponent}
      />
      <HtmlInput
        value={content}
        name="content"
        label="Content"
        height="60px"
        setCurrentComponent={setCurrentComponent}
      />
    </FormWrapper>
  )
}
