import React from 'react'
import { inject, observer } from 'mobx-react'
import { Img } from 'react-image'
import styled  from 'styled-components'

import { Container, FormWrapper } from '/components/theme/default/base'
import { MediaInputPB } from '/components/admin/page/page_builder/form-inputs/Media'

/**
 * This is a work in progress. There is not much functionality here.
 */
export const Navbar = inject('store')(
  observer(({ id, store, logo }) => {
    console.log(logo)
    return (
      <Header>
        <NavbarWrapper>
          <NavContainer>
            <LogoWrap>
              { logo?.media_center && (
                <Img
                  src={logo.media_center.media_thumbnail}
                  alt={logo.name}
                />
              )}
            </LogoWrap>
          </NavContainer>
        </NavbarWrapper>
      </Header>
    )
  })
)

const Header = styled.div`
  box-shadow: 3px 0 15px 0 rgb(0 0 0 / 5%);
  width: 100%;
  transition: all 0.5s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
`

const NavbarWrapper = styled.nav`
  background: ${(props) =>
    props.theme.colors.nav_background_color || props.theme.colors.white};
  color: ${(props) => props.theme.colors.nav_color || props.theme.colors.black};
  padding: 5px 0;
  margin-bottom: 0px !important;
  display: flex;
  height: var(--chalice-top-navbar-height);
`

const NavContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0;

  @media only screen and (max-width: 990px) {
    padding: 5px 20px;
  }
`

const LogoWrap = styled.div`
  display: flex;
  align-items: center;
  z-index: 1002;
  white-space: nowrap;
  color: ${(props) =>
    props.theme.colors.nav_color || props.theme.colors.darkBlue};
  font-size: 14px;
  font-weight: 600;

  img {
    height: var(--chalice-logo-height);
  }
`

export const NavbarForm = ({ id, logo, setCurrentComponent }) => {
  return (
    <FormWrapper>
      <MediaInputPB
        image={logo}
        label="Logo"
        name="logo"
        setCurrentComponent={setCurrentComponent}
      />
    </FormWrapper>
  )
}
