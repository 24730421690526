import { inject, observer } from 'mobx-react'
import {
  CommonPose,
  HeaderPose,
  VisbilityContainer
} from '../../../../../home/Animations'
import {
  Container,
  ChaliceImg,
  interpolate
} from '../../../../../theme/default/base'
import { MainCta } from '../../../../../theme/default/common'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { getImageUrl, getOpacity, hasImage } from '../../utils'

function isNotEmpty(text) {
  return text && text !== '<p><br></p>'
}

export const FeatureList = inject('store')(
  observer(
    ({
      store,
      title,
      description,
      titleColor,
      titleBackgroundColor,
      textColor,
      backgroundColor,
      feature1,
      feature2,
      feature3,
      feature4,
      feature5,
      feature6,
      feature7,
      feature8,
      feature9,
      endText,
      isPreview,
      currentComponent = {}
    }) => {
      return (
        <Wrapper textColor={textColor} backgroundColor={backgroundColor}>
          <div className="content">
            <VisbilityContainer>
              <div className="content-wrapper">
                <Container>
                  <HeaderSection backgroundColor={titleBackgroundColor}>
                    <Header
                      textColor={titleColor}
                      dangerouslySetInnerHTML={{
                        __html: interpolate(store.siteData, title || '')
                      }}
                    />
                    {isNotEmpty(description) && (
                      <Description
                        textColor={titleColor}
                        dangerouslySetInnerHTML={{
                          __html: interpolate(store.siteData, description || '')
                        }}
                      />
                    )}
                  </HeaderSection>
                </Container>
                <Container>
                  <Grid>
                    {isNotEmpty(feature1) && (
                      <li>
                        <CommonPose
                          dangerouslySetInnerHTML={{
                            __html: interpolate(store.siteData, feature1 || '')
                          }}
                        />
                      </li>
                    )}
                    {isNotEmpty(feature2) && (
                      <li>
                        <CommonPose
                          dangerouslySetInnerHTML={{
                            __html: interpolate(store.siteData, feature2 || '')
                          }}
                        />
                      </li>
                    )}
                    {isNotEmpty(feature3) && (
                      <li>
                        <CommonPose
                          dangerouslySetInnerHTML={{
                            __html: interpolate(store.siteData, feature3 || '')
                          }}
                        />
                      </li>
                    )}
                    {isNotEmpty(feature4) && (
                      <li>
                        <CommonPose
                          dangerouslySetInnerHTML={{
                            __html: interpolate(store.siteData, feature4 || '')
                          }}
                        />
                      </li>
                    )}
                    {isNotEmpty(feature5) && (
                      <li>
                        <CommonPose
                          dangerouslySetInnerHTML={{
                            __html: interpolate(store.siteData, feature5 || '')
                          }}
                        />
                      </li>
                    )}
                    {isNotEmpty(feature6) && (
                      <li>
                        <CommonPose
                          dangerouslySetInnerHTML={{
                            __html: interpolate(store.siteData, feature6 || '')
                          }}
                        />
                      </li>
                    )}
                    {isNotEmpty(feature7) && (
                      <li>
                        <CommonPose
                          dangerouslySetInnerHTML={{
                            __html: interpolate(store.siteData, feature7 || '')
                          }}
                        />
                      </li>
                    )}
                    {isNotEmpty(feature8) && (
                      <li>
                        <CommonPose
                          dangerouslySetInnerHTML={{
                            __html: interpolate(store.siteData, feature8 || '')
                          }}
                        />
                      </li>
                    )}
                    {isNotEmpty(feature9) && (
                      <li>
                        <CommonPose
                          dangerouslySetInnerHTML={{
                            __html: interpolate(store.siteData, feature9 || '')
                          }}
                        />
                      </li>
                    )}
                  </Grid>
                  {endText && (
                    <HeaderSection backgroundColor={backgroundColor}>
                      <Description
                        textColor={textColor}
                        dangerouslySetInnerHTML={{ __html: endText }}
                      />
                    </HeaderSection>
                  )}
                </Container>
              </div>
            </VisbilityContainer>
          </div>
        </Wrapper>
      )
    }
  )
)

const Wrapper = styled.div`
  color: ${(props) => props.textColor};
  background-color: ${(props) => props.backgroundColor};
`

const HeaderSection = styled.div`
  background-color: ${(props) => props.backgroundColor};
`

const Header = styled(CommonPose)`
  color: ${(props) => props.textColor};
  padding: 20px;
  text-align: center;
  font-size: ${(props) => props.theme.fontSizes.medium} !important;
  font-weight: 600;

  p {
    margin: 0;
  }
`

const Description = styled(CommonPose)`
  color: ${(props) => props.textColor};
  padding: 0 0 20px 0;
  text-align: center;
  font-size: 1.2rem;

  p {
    margin: 0;
  }
`

const Grid = styled.ul`
  padding: 40px 5px 10px 5px;
  margin: 0 auto;
  max-width: 800px;

  columns: 2;
  column-gap: 20px;
  -webkit-columns: 2;
  -moz-columns: 2;

  ${(props) => props.theme.media.tablet`
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  `}

  li {
    padding-bottom: 10px;
    margin-right: 20px;
  }
`

const More = styled.div``

const Column = styled.div`
  width: 50%;
`
