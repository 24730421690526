import { inject, observer } from 'mobx-react'
import {
  CommonPose,
  HeaderPose,
  VisbilityContainer
} from '../../../../../home/Animations'
import {
  Container,
  ChaliceImg,
  interpolate
} from '../../../../../theme/default/base'
import { MainCta } from '../../../../../theme/default/common'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { getImageUrl, getOpacity, hasImage } from '../../utils'

export const TextBlock = inject('store')(
  observer(
    ({
      store,
      content,
      textColor,
      backgroundColor,
      isPreview,
      currentComponent = {}
    }) => {
      return (
        <Wrapper textColor={textColor} backgroundColor={backgroundColor}>
          <div className="content">
            <VisbilityContainer>
              <div className="content-wrapper">
                <Container>
                  <Text
                    backgroundColor={backgroundColor}
                    textColor={textColor}
                    dangerouslySetInnerHTML={{
                      __html: interpolate(store.siteData, content || '')
                    }}
                  />
                </Container>
              </div>
            </VisbilityContainer>
          </div>
        </Wrapper>
      )
    }
  )
)

const Wrapper = styled.div`
  color: ${(props) => props.textColor};
  background-color: ${(props) => props.backgroundColor};
  padding: 2rem 0;
`

const Text = styled.div`
  font-size: 1.2rem;
`
